
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/demo/demo.css";
import "./assets/scss/paper-kit.scss";
import "./assets/css/animate.css";
import "./assets/css/responsive.css";

import LandingPage from "./views/examples/LandingPage";

ReactDOM.render(

  <BrowserRouter>
    <Switch>
      <Route
        path="/"
        render={props => <LandingPage {...props} />}
      />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);