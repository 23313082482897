import React from 'react';
import {
    Container,
    Row,
    Col
} from "reactstrap";

function AboutUs() {
    return (
        <div className="section text-center">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" md="8">
                        {/* <h2 className="title">What does Nittime do for you?</h2> */}
                        <h2 className="title">Nittime sizin için neler yapar?</h2>
                        <h5 className="description">
                            Nittime üretiminizi gerçek zamanlı kontrol ederek her yerden kontrol etmenize yardımcı olur.
                        </h5>
                        {/* <h5 className="description">
                            Increase your productivity with the control of Nittime from anywhere in real time.
                        </h5> */}
                        <br />
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-money-coins" />
                            </div>
                            <div className="description">
                                {/* <h4 className="info-title">Income Analysis</h4> */}
                                <h4 className="info-title">Gelen Analizi</h4>
                                <p className="description">
                                    Nittime gelen ürünlerinizi takip ederek sizi sürekli eski kayıtlarınıza göz atmaktan kurtarır.
                                </p>
                                {/* <p className="description">
                                    Nittime tells you the total profit in real time. You do not need to check your
                                    old records anymore to see the total profit.
                                </p> */}
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-bulb-63" />
                            </div>
                            <div className="description">
                                {/* <h4 className="info-title">Consultancy</h4> */}
                                <h4 className="info-title">Danışmanlık</h4>
                                <p className="description">
                                    Nittime size iş ortaklarımızla birlikte verimliliği artırmak için danışmanlık hizmeti sunar.
                                </p>
                                {/* <p className="description">
                                    Nittime offers consultancy service to our business partners with the aim of increasing efficiency.
                                </p> */}
                                <br />
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-chart-bar-32" />
                            </div>
                            <div className="description">
                                {/* <h4 className="info-title">Artificial Intelligence</h4> */}
                                <h4 className="info-title">Yapay Zeka</h4>
                                <p className="description">
                                    Nittime şirketinizde olup biteni analiz eder ve size gelecekte oluşabilecek sorunlar hakkında uyarılar verir.
                                </p>
                                {/* <p className="description">
                                    Nittime analyzes your behavior and gives you warnings about possible problems which may happen in the future.
                                </p> */}
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-sun-fog-29" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Üretim Analizi</h4>
                                <p className="description">
                                    Nittime gerçek zamanlı olarak makinelerinizin üretimini takip eder.
                                    Bu sayede üretiminizin miktarını canlı olarak izleyebilirsiniz.
                                </p>
                                {/* <h4 className="info-title">Production Analysis</h4> */}
                                {/* <p className="description">
                                    Nittime shows you production of knitting machines in real time.
                                    With this feature you don’t need to weigh every time.
                                </p> */}
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-album-2" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Raporlama</h4>
                                <p className="description">
                                    Nittime çalışan veya durdurulmuş makinelerinizi izler ve üretim planı oluşturmanıza yardımcı olur.
                  </p>
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-bulb-63" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">İş Gücü Yönetimi</h4>
                                {/* <h4 className="info-title">Workforce Management</h4> */}
                                <p className="description">
                                    Nittime iş gücünüzün doğru ve verimli olarak yönetilmesine yardımcı olur.
                                </p>
                                {/* <p className="description">
                                    Nittime ensures the correct use and management of your workforce.
                                </p> */}
                                <br />
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-chart-bar-32" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Ürün Takibi</h4>
                                <p className="description">
                                    Nittime ürünlerinizin nerede olduğunu size gerçek zamanlı olarak gösterir. Bu özellik ürünleriniz bir arada daha kolay takip etmenizi sağlar.
                                </p>
                                {/* <h4 className="info-title">Goods Tracking</h4> */}
                                {/* <p className="description">
                                    Nittime shows where your goods are parked. This feature makes you to see your total goods.
                                </p> */}
                                <br />
                                <br />
                            </div>
                        </div>
                    </Col>
                    <Col md="3">
                        <div className="info">
                            <div className="icon icon-info">
                                <i className="nc-icon nc-money-coins" />
                            </div>
                            <div className="description">
                                <h4 className="info-title">Bütçe Yönetimi</h4>
                                <p className="description">
                                    Nittime gelir ve giderler kalemlerinizi sizin için analiz eder ve raporlar.
                  </p>
                            </div>
                        </div>
                    </Col>
                </Row>

            </Container>
        </div>

    )
}

export default AboutUs;