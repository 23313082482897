import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
//import {About} from "./src/views/About.js";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";
import { Link} from 'react-scroll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faHome, faBuilding, faFileSignature } from '@fortawesome/free-solid-svg-icons'


function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar

      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            to="/"
            style={{ padding: 0 }}
          >
            <img
              style={(document.documentElement.scrollTop < 300 ||
                document.body.scrollTop > 300) ? { width: 150, height: 80, cursor: 'pointer' } : { width: 80, height: 80, cursor: 'pointer' }}
              alt="..."
              src={(document.documentElement.scrollTop < 300 ||
                document.body.scrollTop > 300) ? require("assets/img/logoTyp.png") : require("assets/img/icon1.png")}
            />
          </NavbarBrand>
          <button
            aria-controls="nav-content"
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <FontAwesomeIcon size="2x" icon={!navbarCollapse ? faBars : faTimes} color="white" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <Link style={{ cursor: 'pointer' }} activeClass="active" className="nav-link" to="anasayfa" spy={true} smooth={true} duration={500} >
                <FontAwesomeIcon icon={faHome} color="white" /> Anasayfa
              </Link>
            </NavItem>
            <NavItem>
              <Link style={{ cursor: 'pointer' }} activeClass="active" className="nav-link" to="hakkimizda" spy={true} smooth={true} duration={500} >
                <FontAwesomeIcon icon={faBuilding} color="white" /> Hakkımızda
              </Link>
            </NavItem>
            <NavItem>
              <Link style={{ cursor: 'pointer' }} activeClass="active" className="nav-link" to="iletisim" spy={true} smooth={true} duration={500} >
                <FontAwesomeIcon icon={faFileSignature} color="white" /> İletişim
              </Link>
            </NavItem>
            {/* <NavItem>

               <NavLink to="/" tag={Link}>
                <i className="nc-icon nc-layout-11" /> Home
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink to="/hakkimizda">
                <i className="nc-icon nc-book-bookmark" /> About
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink to="/services" >
                <i className="" /> Product
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/services">
                <i className="" /> SERVICES
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/consultancy" >
                <i className="nc-icon nc-layout-11" /> Consultancy
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/training">
                <i className="nc-icon nc-layout-11" /> Training
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/iletisim">
                <i className="nc-icon nc-layout-11" /> CONTACT
              </NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Container>
    </Navbar >
  );
}

export default ExamplesNavbar;
