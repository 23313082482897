import React from "react";

// reactstrap components
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import axios from 'axios';
import SuccessAlert from "./SuccessAlert";

const initialState = {
  name: '',
  email: '',
  body: '',
  isSubmitted: false,
  err: false,
  nameError: "",
  emailError: "",
  bodyError: "",
  dataSource: null,
  ipDataSource: null,
  ip: "",
  successmsg: false
};

export default class EmbededContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = initialState
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  componentWillMount() {
    fetch('https://api.ipify.org?format=json')
      .then((Respo) => Respo.json())
      .then((findIp) => {
        this.setState({
          ipDataSource: findIp.ip
        })
      })
  }
  componentDidMount() {
    this.setState({
      ip: JSON.stringify(this.state.ipDataSource)
    })

    fetch("http://ip-api.com/json/" + this.state.ip)
      .then((Response) => Response.json())
      .then((findResponse) => {
        console.log(findResponse.country)
        this.setState({
          dataSource: JSON.stringify(findResponse)
        })
      })
  }
  validate = () => {
    let nameError = "";
    let emailError = "";
    let bodyError = "";

    if (!this.state.name) {
      nameError = "İsim alanı boş bırakılamaz";
    }
    if (!this.state.email) {
      emailError = "Email alanı boş bırakılamaz";
    }
    if (!this.state.body) {
      bodyError = "Mesaj alanı boş bırakılamaz";
    }
    else if (!this.state.email.includes("@")) {
      emailError = "Geçesiz email adresi";
    }

    if (this.state.name.length > 40) {
      nameError = "İsim çok uzun !!!";
    }
    if (this.state.email.length > 30) {
      emailError = "email çok uzun !!!";
    }
    if (this.state.body.length > 200) {
      bodyError = "Mesaj alanı çok uzun !!!";
    }
    if (emailError || nameError || bodyError) {
      this.setState({ emailError, nameError, bodyError });
      return false;
    }

    return true;
  };
  handleSubmit(event) {
    event.preventDefault();
    const isValid = this.validate();
    if (isValid) {
      axios.post(`/api/main/submit-contact`,
        {
          name: this.state.name,
          email: this.state.email,
          description: this.state.body,
          location: this.state.dataSource,
        })
        .then(res => {
          this.setState({
            isSubmitted: true,
            err: false,
            successmsg: true
          })
        })
        .catch(error => {
          this.setState({
            err: true,
            isSubmitted: false
          })
          this.setState(initialState);
        })
    }
  }
  render() {
    return (
      <div className="section">
        <Container>
          <br />
          <br />
          <Row>
            <br />
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="text-center">Bizimle iletişime geçin</h2>
              <Form className="contact-form" onSubmit={this.handleSubmit}>
                <Row>
                  <Col md="6">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input name="name" placeholder="Adınız ve Soyadınız" type="text"
                        value={this.state.name}
                        onChange={this.handleChange} />
                    </InputGroup>
                    <div className="error-message">
                      {this.state.nameError}
                    </div>
                  </Col>
                  <Col md="6">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="nc-icon nc-email-85" />
                        </InputGroupText>
                      </InputGroupAddon >
                      <Input name="email" placeholder="Email Adresi" type="text"
                        value={this.state.email}
                        onChange={this.handleChange} />
                    </InputGroup>
                    <div className="error-message">
                      {this.state.emailError}
                    </div>
                  </Col>
                </Row>
                <br />
                <Input name="body"
                  placeholder="Mesajınız"
                  type="textarea"
                  rows="4"
                  value={this.state.body}
                  onChange={this.handleChange}
                />
                <div className="error-message">
                  {this.state.bodyError}
                </div>
                <Row>
                  <Col className="ml-auto mr-auto" md="4">
                    <Button className="btn-fill" color="danger" size="lg" type="submit">
                      Gönder
                                </Button>
                  </Col>
                </Row>
                <Row>
                  <Col className="ml-auto mr-auto" md="9">
                    {this.state.successmsg && <SuccessAlert />}
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
};